import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import r from '../assets/images/test.png';
import googlePlay from '../assets/images/google-play.svg';
import appStore from '../assets/images/app-store.svg';
import { useTranslation } from "react-i18next";
export default function DownloadApp() {
    const { i18n, t } = useTranslation();  
     const directionStyle =
       i18n.language === "ar"
         ? { direction: "rtl", textAlign: "center" }
         : { direction: "ltr", textAlign: "center" };
  return (
    <>
      <Grid container spacing={0} sx={{ height: "auto" }}>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#17255E", 
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={r}
              alt="Mobile"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover", 
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(23, 37, 94)",
            color: "white",
            textAlign: "center",
            borderTopLeftRadius: { md: "70px", xs: "0px" },
            borderTopRightRadius: { md: "70px", xs: "0px" },
            borderBottomLeftRadius: { md: "0px", xs: "0px" },
            overflow: "hidden",
          }}
        >
          <Box sx={{ padding: 4 ,...directionStyle }}>
            <Typography variant="h1" sx={{ marginBottom: 2 }}>
              {t("home.DownloadApp.title")}
            </Typography>
            <Typography
              variant="h5"
              sx={{ marginBottom: 4, fontSize: "19px", lineHeight: 1.6 }}
            >
              {t("home.DownloadApp.description")}
            </Typography>

            {/* عرض صور روابط التحميل */}
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <a
   
                   href="https://apps.apple.com/us/app/digital-dinar/id6478132433"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appStore}
                  alt={t(" home.DownloadApp.appStoreLink")}
                  style={{
                    width: "160px",
                    height: "auto",
                  }}
                />
              </a>
              <a
               href="https://play.google.com/store/apps/details?id=ly.libyapost.dinar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={googlePlay}
                  alt={t("home.DownloadApp.googlePlayLink")}
                  style={{
                    width: "160px",
                    height: "auto",
                  }}
                />
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
