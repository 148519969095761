import React, { useRef, useState, useEffect } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import SAHARY from "../assets/images/SAHARY.png";
import NORAN from "../assets/images/NORAN.svg";
import LogoCBL from "../assets/images/LogoCBL.svg";
import MADAR from "../assets/images/MADAR.png";
import LIBYANA from "../assets/images/LIBYANA.png";

import ANAN from "../assets/images/ANAN.svg";
import { useTranslation } from "react-i18next";



export default function PaymentMethods() {
    const { i18n, t } = useTranslation();  
  const scrollRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
const images = [NORAN, LogoCBL, SAHARY, MADAR, LIBYANA, ANAN];


  useEffect(() => {
    const centerImage = () => {
      if (scrollRef.current) {
        const currentImage = scrollRef.current.children[activeIndex];
        const scrollBoxCenter = scrollRef.current.offsetWidth / 2;
        const imageCenter =
          currentImage.offsetLeft + currentImage.offsetWidth / 2;
        const scrollTo = imageCenter - scrollBoxCenter;
        scrollRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
      }
    };
    centerImage();
  }, [activeIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
    }, 2000); 

    return () => clearInterval(interval); 
  }, [images.length]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseLeave = () => setIsDragging(false);
  const handleMouseUp = () => setIsDragging(false);

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };



  const handleImageClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <Grid sx={{ height: "auto", marginTop: "50px" }}>
      <Box className="container">
        <Typography variant="h1" sx={{ marginBottom: 2 }}>
          {t("home.PaymentMethods.title")}
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: "19px", lineHeight: 1.6, color: "#666" }}
        >
          {t("home.PaymentMethods.description")}
        </Typography>
      </Box>

      <Box
        ref={scrollRef}
        className="scroll-box "
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        sx={{
          direction: "rtl",
          display: "flex",
          gap: 12,
          marginTop: "20px",
          marginBottom: "20px",
          overflowX: "scroll",
          "&::-webkit-scrollbar": { display: "none" },
          "@media (max-width: 768px)": {
            img: { width: "30%" },
          },
          "@media (min-width: 769px)": {
            img: { width: "10%" },
          },
        }}
      >
        {images.map((src, index) => (
          <Box
            key={index}
            component="img"
            src={src}
            alt={`شريك ${index + 1}`}
            onClick={() => handleImageClick(index)}
            sx={{
              height: "100%",
              padding: "10px",
              objectFit: "contain",
              borderRadius: "8px",
              cursor: "pointer",
              transition: "transform 0.5s ease, opacity 0.5s ease",
              opacity: activeIndex === index ? 1 : 0.7,
              transform: activeIndex === index ? "scale(2)" : "scale(1)",
            }}
          />
        ))}
      </Box>
    </Grid>
  );
}
