import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import mobilepic from "../assets/images/homepageapp.png";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";  
import { useTranslation } from 'react-i18next';

function FirstSection() {
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.3 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.3 });
  const {i18n, t } = useTranslation();  

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      sx={{
        marginTop: "50px", 
        "@media (max-width: 600px)": {
          marginTop: "0px",
        },
      }}
      className="first-section"
    >
 
<Grid
  item
  xs={12}
  sm={6}
  order={{ xs: 1, sm: 2 }} 
  ref={ref2}
  className="flex-container"
>
  <motion.div
    className="box-container"
    style={{
      textAlign: i18n.language === 'ar' ? 'right' : 'left', 
      direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
    }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: inView2 ? 1 : 0, y: inView2 ? 0 : 20 }}
    transition={{ opacity: { duration: 0.6 }, y: { duration: 0.6 } }}
  >
    <Typography
      variant="h4"
      className="heading"
      sx={{
        fontWeight: "bold",
        marginBottom: "16px",
        color: "text.primary",
      }}
    >
      {t('home.FirstSection.title')}
    </Typography>

    <Typography
      variant="body1"
      className="body-text"
      sx={{
        marginBottom: "16px",
        lineHeight: 1.5,
        color: "text.primary",
        textAlign: 'justify', 
        direction: i18n.language === 'ar' ? 'rtl' : 'ltr', 
      }}
    >
      {t('home.FirstSection.description')}
    </Typography>
    <Button
  className="cta-button"
  variant="contained"
  color="primary"
  sx={{
    padding: "12px 24px",
    fontWeight: "bold",
    ml: "auto",
    display: "block",
    "@media (max-width: 600px)": {
      margin: "10px 0",
    },
    textAlign: i18n.language === 'ar' ? 'right' : 'left',
    flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row', 
    display: 'flex', 
    justifyContent: i18n.language === 'ar' ? 'flex-start' : 'flex-end', 
  }}
>
  {i18n.language === 'ar' ? 'ابدأ الآن' : 'Get Started'}
</Button>

  </motion.div>
</Grid>



    
      <Grid
        item
        xs={12}
        sm={6}
        order={{ xs: 2, sm: 1 }} 
        ref={ref1}
      >
        <motion.div
          style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: inView1 ? 1 : 0, y: inView1 ? 0 : 20 }}
          transition={{ opacity: { duration: 0.6 }, y: { duration: 0.6 } }}
        >
          <Box className="circle-container-right" style={{ position: "absolute", top: "10%", right: "1%" }}>
            <div className="circle-grid">
              {Array.from({ length: 25 }).map((_, i) => (
                <div key={i} className="circle"></div>
              ))}
            </div>
          </Box>

          <Box className="circle-container-left" style={{ position: "absolute", bottom: "10%", left: "10%" }}>
            <div className="circle-grid-square">
              {Array.from({ length: 12 }).map((_, i) => (
                <div key={i} className="circle-square"></div>
              ))}
            </div>
          </Box>

          <motion.img
            src={mobilepic}
            alt="Mobile Picture"
            style={{
              width: "45%",
              height: "auto",
              display: "block",
              "@media (max-width: 600px)": {
                width: "80%", 
              },
            }}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: inView1 ? 1 : 0, scale: inView1 ? 1 : 0.9 }}
            transition={{ duration: 0.6 }}
          />
        </motion.div>
      </Grid>
    </Grid>
  );
}

export default FirstSection;
