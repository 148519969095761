import React from 'react';
import { motion } from 'framer-motion';  // استيراد motion من framer-motion
import Navbar from '../component/Navbar';
import FirstSection from '../component/FristSeaction';
import SecondSeaction from '../component/SecondSeaction';
import ThirdSeactio from '../component/ThirdSeactio';
import Fourthseaction from '../component/Fourthseaction';
import PaymentMethods from '../component/PaymentMethods';
import DownloadApp from '../component/DownloadApp';
import Footer from '../component/Footer';
import '../css/home.css';
import { Element } from 'react-scroll';
import '../i18n.js'

function Home() {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main className="centered">
        <motion.div
          className="animate-on-scroll"
          initial={{ opacity: 0 }} 
          whileInView={{ opacity: 1 }}  
          transition={{ duration: 1 }}  
        >
               <Element name="FirstSection"> <FirstSection /> </Element>   
        </motion.div>
        
        <motion.div
          className="animate-on-scroll"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Element name="SecondSection"> <SecondSeaction /> </Element>
        </motion.div>

        <motion.div
          className="animate-on-scroll"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <ThirdSeactio />
        </motion.div>

        <motion.div
          className="animate-on-scroll"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Fourthseaction />
        </motion.div>

        <motion.div
          className="animate-on-scroll"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <PaymentMethods />
        </motion.div>

        <motion.div
          className="animate-on-scroll"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
           <Element name="DownloadApp"> <DownloadApp /> </Element>
        </motion.div>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default Home;
