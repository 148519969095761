import React, { useState,useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import { Link as ScrollLink } from 'react-scroll';
import logo from "../assets/images/Logor.png";
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const {i18n, t } = useTranslation();  

  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setLanguage] = useState(i18n.language || "ar");

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const defaultLang = 'ar'; 
    i18n.changeLanguage(defaultLang);
    setLanguage(defaultLang);
  }, [i18n]);
  
  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };
  
  const directionStyle = language === "AR" ? { direction: "rtl", textAlign: "right" } : { direction: "ltr", textAlign: "left" };

  return (
    <AppBar 
      position="fixed"  
      sx={{ 
        bgcolor: "#fff", 
        boxShadow: "0 2px 8px rgba(23, 37, 94, 0.3)", 
        ...directionStyle, 
        width: '100%',  
        zIndex: 1100,   
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* الصورة */}
        <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
          <img
            src={logo}
            alt="Logo"
            style={{
              height: "40px", 
              width: "auto",
            }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 3,
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <ScrollLink 
            to="FirstSection" 
            smooth={true} 
            duration={500} 
            style={{ textDecoration: 'none' }}
          >
            <Typography color="text.primary" sx={{ fontWeight: "bold" }}>
            {t('home.navbar.home')}
            </Typography>
          </ScrollLink>
          <ScrollLink 
            to="SecondSection" 
            smooth={true} 
            duration={500} 
            style={{ textDecoration: 'none' }}
          >
            <Typography color="text.primary" sx={{ fontWeight: "bold" }}>
            {t('home.navbar.about')}
            </Typography>
          </ScrollLink>
          <ScrollLink 
            to="DownloadApp" 
            smooth={true} 
            duration={500} 
            style={{ textDecoration: 'none' }}
          >
            <Typography color="text.primary" sx={{ fontWeight: "bold" }}>
            {t('home.navbar.contact')}
            </Typography>
          </ScrollLink>
        </Box>
        {/* أيقونات اللغة والقائمة */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            onClick={() => switchLanguage(language === 'ar' ? 'en' : 'ar')} 
            sx={{
              marginRight: 1,
              color: "text.primary",
            }}
          >
            <LanguageIcon />
            <Typography
              sx={{
                fontSize: "14px",
                ml: 1,
                color: "text.primary",
              }}
            >
            {language === "ar" ? "عربي" : "EN"}
            </Typography>
          </IconButton>

          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              color="inherit"
              edge="end"
              onClick={handleMenuOpen}
              sx={{ color: "text.primary" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: language === "AR" ? "right" : "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: language === "AR" ? "right" : "left",
              }}
            >
              {/* استخدام ScrollLink داخل قائمة الـ Menu */}
              <MenuItem onClick={handleMenuClose}>
                <ScrollLink 
                  to="FirstSection" 
                  smooth={true} 
                  duration={500} 
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {t('home.navbar.home')}
                </ScrollLink>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ScrollLink 
                  to="SecondSection" 
                  smooth={true} 
                  duration={500} 
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                {t('home.navbar.about')}
                </ScrollLink>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ScrollLink 
                  to="DownloadApp" 
                  smooth={true} 
                  duration={500} 
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                 {t('home.navbar.contact')}
                </ScrollLink>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
