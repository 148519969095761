import React from 'react';
import { Grid, Box, Typography, Button } from "@mui/material";
import mobilepic from '../assets/images/Wallet-bro.png';
import { motion } from 'framer-motion'; 
import { useTranslation } from 'react-i18next';

function Fourthseaction() {
  const {i18n, t } = useTranslation();  

  const fadeInVariant = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const slideInVariant = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
  };

  const zoomInVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <>
      <Grid 
        container 
        spacing={3}  
        justifyContent="center" 
        alignItems="center"
      >
     
        <Grid item xs={12} sm={6}  className="flex-container" sx={{ order: { xs: 2, sm: 1 } }}>
          <motion.div
            variants={slideInVariant}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1.5, ease: 'easeOut' }}
          >
            <Box
              className="box-container"
              sx={{
                textAlign: 'right',
                direction: 'rtl',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                  marginBottom: '16px',
                  color: 'text.primary',
                  textAlign: i18n.language === 'ar' ? 'right' : 'left', 
                }}
              >
               {t('home.FourthSection.title')}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  marginBottom: '16px',
                  lineHeight: 1.5,
                  color: 'text.primary',
                  textAlign: 'justify', 
                  direction: i18n.language === 'ar' ? 'rtl' : 'ltr',  
                }}
              >
            {t('home.FourthSection.description')}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: '12px 24px',
                  textAlign: i18n.language === 'ar' ? 'right' : 'left',  
                  fontWeight: 'bold',
                  ml: 'auto',
                  display: 'block',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#17255e',
                    color: 'white',
                  },
                }}
              >
               {t('home.buttonText')}
              </Button>
            </Box>
          </motion.div>
        </Grid>


        <Grid item xs={12} sm={6} sx={{ order: { xs: 1, sm: 2 } }}>
          <motion.div
            variants={zoomInVariant}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1.5, ease: 'easeOut' }}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          >
            <Box position="relative" display="flex" justifyContent="center" alignItems="center">
              <Box className="circle-container-right" style={{ position: 'absolute', top: '10%', right: '10%' }}>
                <div className="circle-grid">
                  {Array.from({ length: 25 }).map((_, i) => (
                    <div key={i} className="circle"></div>
                  ))}
                </div>
              </Box>

              <Box className="circle-container-left" style={{ position: 'absolute', bottom: '10%', left: '10%' }}>
                <div className="circle-grid-square">
                  {Array.from({ length: 12 }).map((_, i) => (
                    <div key={i} className="circle-square"></div>
                  ))}
                </div>
              </Box>

              <motion.img
                src={mobilepic}
                alt="Mobile Picture"
                style={{
                  width: '65%',
                  height: 'auto',
                }}
                variants={fadeInVariant}
                initial="hidden"
                animate="visible"
                transition={{ duration: 1.5, ease: 'easeOut' }}
              />
            </Box>
          </motion.div>
        </Grid>
      </Grid>
    </>
  );
}

export default Fourthseaction;
