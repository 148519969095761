import React from "react";
import { Box, Grid, Typography, IconButton, Divider } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import logo from "../assets/images/logore.png";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { i18n } = useTranslation();
  const language = i18n.language;

  const directionStyle =
    language === "ar"
      ? { direction: "rtl", textAlign: "right" }
      : { direction: "ltr", textAlign: "left" };

  return (
    <Grid
      sx={{
        backgroundColor: "rgba(23, 37, 94)",
        color: "#ffffff",
        padding: "10px 0",
        ...directionStyle,
      }}
    >
      <Grid
        container
        justifyContent="center"
        spacing={2}
       
      >
        <Grid item xs={12} sm={4} sx={directionStyle}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <img src={logo} alt="Logo" style={{ maxWidth: "30px" }} />
            <Typography
              variant="h4"
              component="div"
              fontWeight="bold"
              sx={{ mt: 1 }}
            >
              {i18n.t("home.Footer.title")}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{ mt: 2, mx: { xs: 2, md: 10 }, textAlign: "center" }}
          >
            {i18n.t("home.Footer.description")}
          </Typography>
        </Grid>

        <Grid item container xs={12} spacing={4} md={3} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Typography variant="body2">
              {i18n.t("home.Footer.links.about")}
            </Typography>
            <Typography variant="body2">
              {i18n.t("home.Footer.links.contact")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} textAlign="center">
            <Typography variant="body2">
              {i18n.t("home.Footer.links.home")}
            </Typography>
            <Typography variant="body2">
              {i18n.t("home.Footer.links.products")}
            </Typography>
            <Typography variant="body2">
              {i18n.t("home.Footer.links.careers")}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} textAlign="center" sx={{ mt: 5 }}>
          <IconButton color="inherit">
            <FacebookIcon />
          </IconButton>
          <IconButton color="inherit">
            <LinkedInIcon />
          </IconButton>
          <IconButton color="inherit">
            <TwitterIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: "#ffffff", my: 3, mx: 10 }} />
      <Box mt={3} textAlign="center" pt={2}>
        <Typography variant="body2" component="span" sx={{ mx: 2 }}>
          {i18n.t("home.Footer.privacyPolicy")}
        </Typography>
        <Typography variant="body2" component="span" sx={{ mx: 2 }}>
          {i18n.t("home.Footer.termsOfService")}
        </Typography>
        <Typography variant="body2">
          {i18n.t("home.Footer.copyright")}
        </Typography>
      </Box>
    </Grid>
  );
}
