import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { Home } from "./pages";
import './i18n'; // استيراد إعدادات الترجمة

// إنشاء الثيم
const theme = createTheme({
  palette: {
    primary: {
      main: "#0a1929",
    },
    secondary: {
      main: "#FE9322", 
    },
    text: {
      primary: "#17255e", 
      secondary: "#FE9322", 
    },
  },
  typography: {
    fontFamily: "'Tajawal', sans-serif", 
    h1: {
      fontSize: "2.5rem",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1rem",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
