import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import mobilepic from '../assets/images/pay.png';
import { motion } from 'framer-motion';  
import { useTranslation } from 'react-i18next';

function SecondSection() {
 
  const fadeInVariant = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const {i18n, t } = useTranslation();  

  return (
    <Grid 
      container 
      style={{ backgroundColor: 'rgba(23, 37, 94)', borderRadius: '15px' }} 
    >
      <Grid 
  item 
  xs={12} 
  md={6} 
  container 
  alignItems="center" 
  justifyContent="center" 
  sx={{ 
    color: 'white',
    padding: 0,
    margin: 0,
    textAlign: i18n.language === 'ar' ? 'right' : 'left',  
    direction: i18n.language === 'ar' ? 'rtl' : 'ltr',    
    boxSizing: 'border-box',
    '@media (max-width: 600px)': {
      order: 2,
      textAlign: 'center',
    },
  }}
>
  <motion.div
    variants={fadeInVariant}
    initial="hidden"
    animate="visible"
    transition={{ duration: 1.5, ease: 'easeOut' }}
  >
    <Box className="box-container">
      <Typography 
        variant="h4" 
        sx={{ 
          fontWeight: 'bold', 
          marginBottom: '10px', 
          color: 'white',
          textAlign: i18n.language === 'ar' ? 'right' : 'left',  
        }}
      >
        {t('home.SecondSection.title')}
      </Typography>

      <Typography 
        variant="body1" 
        sx={{ 
          marginBottom: '10px', 
          color: 'white', 
          textAlign: 'justify',  
          direction: i18n.language === 'ar' ? 'rtl' : 'ltr',  
        }}
      >
        {t('home.SecondSection.description')}
      </Typography>

      <Button 
        variant="contained" 
        sx={{ 
          display: 'block', 
          marginLeft: 'auto', 
          marginRight: 0, 
          padding: '12px 24px', 
          fontWeight: 'bold',
          color: 'primary.main', 
          backgroundColor: 'white', 
          '&:hover': {
            backgroundColor: 'grey.300',
          },
        }}
      >
        {t('home.buttonText')} 
      </Button>
    </Box>
  </motion.div>
</Grid>


      <Grid item xs={12} md={6}>
        <motion.div
          variants={fadeInVariant}
          initial="hidden"
          animate="visible"
          transition={{ duration: 1.5, ease: 'easeOut' }}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <img
            src={mobilepic}
            alt="Mobile"
            style={{ 
              width: '80%',  
              height: 'auto',  
              display: 'block',
              borderRadius: '15px', 
            }} 
          />
        </motion.div>
      </Grid>
    </Grid>
  );
}

export default SecondSection;
